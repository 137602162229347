<template>
  <div class="pa-3">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-dialog
        v-model="showForm"
        max-width="1000px"
        persistent
        transition="dialog-top-transition"
        @keydown.esc="close"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row dense>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    autocomplete="off"
                    type="text"
                    dense
                    v-model="editedItem.ref"
                    :readonly="readonly"
                    label="Réference"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="2" md="2">
                  <v-text-field
                    autocomplete="off"
                    ref="levels"
                    class="inputPrice"
                    type="number"
                    dense
                    v-model.number="editedItem.level_id"
                    readonly
                    :label="range_name"
                    hide-spin-buttons
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2" md="2">
                  <v-text-field
                    autocomplete="off"
                    ref="levels"
                    class="inputPrice"
                    type="number"
                    dense
                    v-model.number="editedItem.pos_id"
                    readonly
                    :label="'Position'"
                    hide-spin-buttons
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <v-text-field
                    autocomplete="off"
                    type="text"
                    dense
                    v-model="editedItem.gps"
                    :readonly="readonly"
                    label="GPS"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-container fill-height>
              <v-layout row justify-center align-center v-if="progress">
                <v-progress-circular
                  indeterminate
                  :size="30"
                  :width="7"
                  color="purple"
                ></v-progress-circular>
              </v-layout>
            </v-container>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="save" v-if="!readonly">
              Enregistrer
            </v-btn>
            <v-btn color="blue darken-1" text @click.stop="close">
              Fermer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>

    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </div>
</template>

<script>
//const cursorselect = () => import("./CursorSelect.vue");
import CREATE_BOX from "../graphql/Depot/CREATE_BOX.gql";
import UPDATE_BOX from "../graphql/Depot/UPDATE_BOX.gql";
export default {
  components: {},
  name: "boxform",
  props: {
    item: Object,
    items: Array,
    rack: Object,
    readonly: Boolean,
    showForm: Boolean,
    rack_name: String,
    range_name: String,
    box_name: String,
  },
  data: () => ({
    progress: false,
    valid: true,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",

    kq: 100,
    defaultItem: {},
    editedItem: {},
  }),

  computed: {
    formTitle() {
      if (this.item)
        return this.item.id < 0
          ? "Nouv. " + this.box_name
          : this.box_name +
              "  " +
              (this.item.ref ? this.item.ref : this.item.code);
      else return "";
    },
  },
  watch: {},

  created() {},
  mounted() {
    this.$refs.form.resetValidation();
    if (this.item) {
      if (this.item.id > 0) {
        this.editedItem = Object.assign({}, this.item);
      } else {
        this.editedItem = Object.assign({}, this.defaultItem);
      }
    }
  },

  methods: {
    close() {
      this.$emit("close", this.items);
    },
    async maj(query, v) {
      let r;
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          r = data.data;
        })
        .catch((error) => {
          this.progress = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    verifie_double() {
      let ok = true;
      let code = "";
      for (let index = 0; index < this.items.length; index++) {
        const element = this.items[index];
        if (this.editedItem.id > 0) {
          if (element.ref) {
            let l = this.items.filter((elm) => elm.id != element.id);
            for (let index = 0; index < l.length; index++) {
              const elm = l[index];
              if (elm.ref && element.ref)
                if (elm.ref.toLowerCase() == element.ref.toLowerCase()) {
                  code = element.ref;
                  ok = false;
                  break;
                }
            }
          }
        } else {
          if (this.editedItem.ref)
            if (
              this.editedItem.ref.toLowerCase() == element.ref.toLowerCase()
            ) {
              code = element.ref;
              ok = false;
            }
        }

        if (ok == false) break;
      }

      if (ok == false) {
        this.snackbar_text = "Erreur code " + code + " en double! verifier";
        this.snackbar_color = "error";
        this.snackbar = true;
        ok = false;
      }
      return ok;
    },
    async save() {
      if (this.$refs.form.validate()) {
        let ok = this.verifie_double();
        if (ok) {
          this.progress = true;
          if (this.editedItem.id > 0) {
            let v = {
              box: {
                id: this.editedItem.id,
                ref: this.editedItem.ref,
                level_id: this.editedItem.level_id,
                pos_id: this.editedItem.pos_id,
                gps: this.editedItem.gps,
                write_uid: this.$store.state.me.id,
              },
            };
            await this.maj(UPDATE_BOX, v);

            this.progress = false;
            this.items.splice(this.item.index, 1, this.editedItem);
            this.$emit("change", this.editedItem);
            this.close();
          } else {
            let v = {
              box: {
                ref: this.editedItem.ref,
                level_id: this.editedItem.level_id,
                pos_id: this.editedItem.pos_id,
                gps: this.editedItem.gps,
                write_uid: this.$store.state.me.id,
              },
            };
            let r = await this.maj(CREATE_BOX, v);
            if (r) {
              this.editedItem.id = r.CreateBox;
              this.items.push(this.editedItem);

              this.progress = false;
              this.close();
            }
            this.$emit("add", this.editedItem);
          }
        }
      }
    },
  },
};
</script>
